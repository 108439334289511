import React from "react"
import { graphql } from "gatsby"
import { GatsbySeo, ProductJsonLd } from "gatsby-plugin-next-seo"
import {
  Product_1,
  Product_2,
  Product_3,
  Product_4,
  Product_5,
  Product_6,
  Product_7,
  Product_8,
} from "../assets/products"
import { File } from "../assets/icons"
import {
  EventSolution,
  FintechSolution,
  PlatformSolution,
  ProductExperienceSolution,
  TrackingSolution,
} from "../assets/one_pagers"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"

export default function Products() {
  const { languages, originalPath } = useI18next() // Get language info
  const { t, i18n } = useTranslation() // Get translation info

  const siteUrl = "https://arcanode.io/products/"

  const items = [
    {
      id: 0,
      image: Product_1,
      text: t("products.product.0.name"),
      detail: t("products.product.0.description"),
      buttonText: t("products.product.0.button"),
      link: "https://tour.arcanode.io",
      file: ProductExperienceSolution,
    },
    {
      id: 1,
      image: Product_2,
      text: t("products.product.1.name"),
      detail: t("products.product.1.description"),
      buttonText: t("products.product.1.button"),
      link: "https://configurator.arcanode.io/",
      file: ProductExperienceSolution,
    },
    {
      id: 2,
      image: Product_3,
      text: t("products.product.2.name"),
      detail: t("products.product.2.description"),
      buttonText: t("products.product.2.button"),
      file: ProductExperienceSolution,
    },
    {
      id: 3,
      image: Product_4,
      text: t("products.product.3.name"),
      detail: t("products.product.3.description"),
      buttonText: t("products.product.3.button"),
      file: PlatformSolution,
    },
    {
      id: 4,
      image: Product_5,
      text: t("products.product.4.name"),
      detail: t("products.product.4.description"),
      buttonText: t("products.product.4.button"),
      file: EventSolution,
    },
    {
      id: 5,
      image: Product_6,
      text: t("products.product.5.name"),
      detail: t("products.product.5.description"),
      buttonText: t("products.product.5.button"),
      file: TrackingSolution,
    },
    {
      id: 6,
      image: Product_7,
      text: t("products.product.6.name"),
      detail: t("products.product.6.description"),
      buttonText: t("products.product.6.button"),
      file: FintechSolution,
    },
    {
      id: 7,
      image: Product_8,
      text: t("products.product.7.name"),
      detail: t("products.product.7.description"),
      buttonText: t("products.product.7.button"),
    },
  ]

  return (
    <>
      <GatsbySeo
        title={t("products.seo.title", "Products")}
        description={t(
          "products.seo.description",
          "Explore Arcanode's innovative product offerings: 3D Product Tours, Configurators, immersive Product Experiences, and advanced Fintech Services. Enhance your business with scalable, interactive solutions."
        )}
        canonical={`${siteUrl}/${i18n.language}${originalPath}`}
        openGraph={{
          url: `${siteUrl}/${i18n.language}${originalPath}`,
          title: t("products.seo.title", "Products"),
          description: t(
            "products.seo.description",
            "Explore Arcanode's innovative product offerings: 3D Product Tours, Configurators, immersive Product Experiences, and advanced Fintech Services. Enhance your business with scalable, interactive solutions."
          ),
          images: [
            {
              url: `${siteUrl}/icons/icon-512x512.png`,
              width: 512,
              height: 512,
              alt: "Arcanode",
            },
          ],
          site_name: "Arcanode",
        }}
        twitter={{
          handle: "@arcanode",
          site: "@arcanode",
          cardType: "summary_large_image",
        }}
        languageAlternates={languages.map(lang => ({
          hrefLang: lang,
          href: `${siteUrl}/${lang}${originalPath}`,
        }))}
      />

      {items.map(item => (
        <ProductJsonLd
          key={item.id}
          name={item.text}
          images={[item.image]}
          description={item.detail}
          brand="Arcanode"
          url={item.link}
        />
      ))}

      <div className="flex justify-center items-center pt-2 py-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-9 px-4">
          {items.map(item => (
            <div
              key={item.id}
              className="relative group bg-gray-200 rounded-lg overflow-hidden shadow-lg"
            >
              <img
                src={item.image}
                alt={item.text}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-90 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none">
                <div className="absolute bottom-3 left-4 text-white text-xxs md:text-xs pr-2 font-bold uppercase">
                  {item.text}
                </div>
                <div className="flex flex-row gap-2 items-center justify-center absolute bottom-3 right-4 pointer-events-auto">
                  {item.file && (
                    <div className="flex items-center cursor-pointer pt-1">
                      <a
                        href={item.file}
                        download
                        className="flex items-center justify-center text-white w-4 h-4"
                      >
                        <File />
                      </a>
                    </div>
                  )}

                  {/* Link to preview */}
                  <div>
                    {item.link && (
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="border border-blue-500 text-white text-xxs uppercase font-bold px-6 py-1 rounded-md bg-transparent"
                      >
                        {item.buttonText}
                      </a>
                    )}
                  </div>
                </div>
                {/* Centered Description */}
                <div className="absolute inset-0 flex items-center justify-center text-white text-center px-4">
                  <p
                    className="text-xs md:text-sm mb-4 py-4"
                    dangerouslySetInnerHTML={{ __html: item.detail }}
                  ></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
